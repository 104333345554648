import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import base_url from "../../api/bootapi";
import FooterNew from "../NavBar/FooterNew";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Nav from "../NavBar/Nav";
toast.configure();

export default function HomeNew() {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const [selectShow, setSelectedShow] = useState(3);

  const [bannerTitle, setBannerTittle] = useState("");
  const [bannerCaption, setBannerCaption] = useState("");
  const [bannerImage, setBannerImg] = useState("");

  const [slideData, setSlideData] = useState();
  const [testimonialData, setTestimonialData] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const location = useLocation();
  const { isClaimed = 0, unique_no = null } = location.state || {};

  useEffect(() => {
    getData();
    getSliderData();
    getTestimonialData();
  }, []);
  useEffect(() => {
    if (window.innerWidth <= 1200 && window.innerWidth > 770) {
      setSelectedShow(2);
    } else if (window.innerWidth <= 770) {
      setSelectedShow(1);
    } else {
      setSelectedShow(3);
    }
  }, []);
  useEffect(() => {
    window.onresize = function () {
      // //console.log(window.innerHeight);
      //console.log(window.innerWidth);
      if (window.innerWidth <= 1200 && window.innerWidth > 770) {
        setSelectedShow(2);
      } else if (window.innerWidth <= 770) {
        setSelectedShow(1);
      } else {
        setSelectedShow(3);
      }
    };
    // if(width <= 1000){
    //   setSelectedShow(2)
    // }
  }, [window.innerWidth]);

  const getData = () => {
    axios
      .get(
        // `${base_url.api1}/cmsapp/cms?id=21` //Live
        // // `${base_url.api1}/cmsapp/cms?id=42` //Test
        `${base_url.api1}/cmsapp/cms_list` //Test
      )
      .then(
        (response) => {
          //console.log(response.data.Cms);
          setBannerTittle(response.data.Cms.title);
          setBannerCaption(response.data.Cms.caption);
          setBannerImg(response.data.Cms.image);
        },
        (error) => {
          //console.log(JSON.stringify(error));
        }
      );
  };

  const getMessage = () => {
    switch (isClaimed) {
      case 1:
        return "Your Claim has been submitted successfully.";
      case 2:
        return "Your booking request for the vehicle has been submitted successfully.";
      case 3:
        return "The inquiry has been received and payment done successfully we will contact you soon.";
      case 4:
        return "Your inquiry has been received but the payment process failed we will contact you soon.";
      default:
        return "Your Signature has been captured successfully. You can now submit the Claim Form.";
    }
  };

  const getSliderData = () => {
    axios
      .get(
        `${base_url.api1}/cmsapp/cms?page_name=home&type=slide` //Test
      )
      .then(
        (response) => {
          //console.log("100", response.data.Cms.cms_slides);
          setSlideData(response.data.Cms.cms_slides);
        },
        (error) => {
          //console.log(JSON.stringify(error));
        }
      );
  };

  const getTestimonialData = () => {
    axios
      .get(
        `${base_url.api1}/cmsapp/cms?page_name=home&type=testimonial` //Test
      )
      .then(
        (response) => {
          //console.log("116", response.data.Cms);
          setTestimonialData(response.data.Cms.cms_slides);
        },
        (error) => {
          //console.log(JSON.stringify(error));
        }
      );
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  if (!slideData || slideData.length === 0) {
    return null; // If no data, you can return null or a placeholder
  }

  const currentSlideData = slideData[currentSlide];
  const titleWithLineBreaks = currentSlideData.title.replace(/\r\n/g, "<br>");
  const captionWithLineBreaks = currentSlideData.caption.replace(
    /\r\n/g,
    "<br>"
  );
  const imageUrl = `https://apilinsoatours.odisoft.in${currentSlideData.image}`;

  // ====================================== *****************************  ========================================

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonialData.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonialData.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Check if testimonialsData is defined and not empty
  if (!testimonialData || testimonialData.length === 0) {
    return <p></p>;
  }

  const currentTestimonial = testimonialData[currentIndex];
  const testimonial = `https://apilinsoatours.odisoft.in${currentTestimonial.image}`;

  return (
    <React.Fragment>
      <Nav />
      {/* <div style={{ position: "relative", height: "25vh" }}>
        <img
          src={bannerbackground}
          alt="Header_Image"
          style={{ height: "100%", width: "100%" }}
        />
        <div className="overlaybg">
          <div style={{ postion: "absolute" }}>
            <h1
              style={{
                width: "100%",
                marginTop: "200px",
                fontSize: "3.5rem",
                textAlign: "center",
                color: "#fff",
                fontFamily: " Caveat,cursive",
              }}
            >
              {tourTitle ? tourTitle : null}
            </h1>
          </div>
        </div>
      </div> */}

      {/* <!-- ***** Enjoy  Spirit  ***** --> */}
      <section
        className="section"
        id="testimonial"
        style={{ textAlign: "center" }}
      >
        <div className="container" style={{ maxWidth: "100%" }}>
          <div className="text-center" style={{ marginBottom: "80px" }}>
            <h1 className="new_tour_head_2" style={{ color: "#005b67" }}>
              Thank You
            </h1>
            <h4
              style={{
                color: "#545267",
                fontWeight: "500",
                fontStyle: "italic",
                marginBottom: "20px",
              }}
            >
              {getMessage()}
            </h4>
            {unique_no && (
              <h5
                style={{
                  color: "#545267",
                  fontWeight: "500",
                  fontStyle: "italic",
                  marginBottom: "110px",
                  textAlign: "center",
                }}
              >
                Your Profile ID :<strong>{unique_no}</strong>
              </h5>
            )}
          </div>
        </div>
      </section>
      {/* <!-- ***** Enjoy  Spirit ***** --> */}

      {/* <FooterNew /> */}
    </React.Fragment>
  );
}
