import React, { useState, useEffect } from "react";
import Nav from "./../NavBar/Nav";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import base_url from "./../../api/bootapi";
import attachmenturl from "./../../api/attachmenturl";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import GalleryViewModal from "./ViewGalleryModal";
import { Col, Row } from "reactstrap";
import FooterNew from "../NavBar/FooterNew";

export default function Gallery() {
  const [backdropopen, setbackdropopen] = useState(false);
  const [allGallery, setGalleryData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const allGallery = useSelector(selectGallery);

  const [bannerTitle, setBannerTittle] = useState("");
  const [bannerCaption, setBannerCaption] = useState("");
  const [bannerImage, setBannerImg] = useState("");

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getAllGallery(1);
  }, []);

  const getData = () => {
    axios
      .get(
        `${base_url.api1}/cmsapp/cms?page_name=Gallery&type=Gallery` //Live
        //`${base_url.api1}/cmsapp/cms?id=43` //Test
      )
      .then(
        (response) => {
          //console.log(response.data.Cms);
          setBannerTittle(response.data.Cms.title);
          setBannerCaption(response.data.Cms.caption);
          setBannerImg(response.data.Cms.image);
        },
        (error) => {
          //console.log(JSON.stringify(error));
        }
      );
  };

  const getAllGallery = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api1}/gallery/gallery_list?status=1&page=` + value1)
      .then(
        (response) => {
          // setGalleryData(response.data.Gallery);
          // dispatch(getGallery(response.data.Gallery));
          setbackdropopen(false);
          const arr = response.data.Gallery;
          // const arr = [1,2,3,4,5,6,7,8];
          // const newArr = [];
          // //console.log(arr);
          // while(arr.length) newArr.push(arr.splice(0,3));
          // //console.log(newArr);

          let slicedGallery = [];
          for (var i = 0; i < response.data.Gallery.length; i += 3) {
            let part_slice = response.data.Gallery.slice(i, 3 + i);
            slicedGallery.push(part_slice);
          }
          setGalleryData(slicedGallery);
          // dispatch(getGallery(newArr));
          // Array.prototype.reshape = function(rows, cols) {
          //   //console.log(rows);
          //   //console.log(cols);
          //   var copy = this.slice(0); // Copy all elements.
          //   this.length = 0; // Clear out existing array.

          //   for (var r = 0; r < rows; r++) {
          //     var row = [];
          //     for (var c = 0; c < cols; c++) {
          //       var i = r * cols + c;
          //       if (i < copy.length) {
          //         row.push(copy[i]);
          //       }
          //     }
          //     this.push(row);
          //   }
          // };
          // let m1 = [1, 2, 3, 4, 5, 6, 7, 8, 9];
          // let m1 = response.data.Gallery;
          // m1.reshape(3, 3); // Reshape array in-place.
          // //console.log(m1);

          // setgetpageno(response.data.total_pages);
          // setTotalTours(response.data.total_count);
          // setcountnumber(value1 - 1);
          // setnumberofElements(response.data.Gallery.length);
          // setSelectedDelete([]);
          // setsearch(false);
          // setbackdropopen(false);
          // document.getElementById("myForm").reset();
        },
        (error) => {
          //   dispatch(getGallery(null));
          setbackdropopen(false);
          //console.log(JSON.stringify(error));
        }
      );
  };

  // //console.log(allGallery);

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Nav />
      {/* <div style={{ position: "relative", height: "80vh" }}>
        <img
          src={`${base_url.api1}/${bannerImage}`}
          alt="Header_Image"
          style={{ height: "100%", width: "100%" }}
        />
        <div className="overlaybg">
          <div style={{ postion: "absolute" }}>
            <h1
              style={{
                width: "100%",
                marginTop: "200px",
                fontSize: "3.5rem",
                textAlign: "center",
                color: "#fff",
                fontFamily: " Caveat,cursive",
              }}
            >
              {bannerTitle}
            </h1>
          </div>
        </div>
      </div> */}

      {/* <div style={{ position: "relative" }}>
        <div className="hero-main" id="logo">
          <div className="hero-main-img">
            <img src={`https://apilinsoatours.odisoft.in/${bannerImage}`} alt="Header_Image" />
            <h1 className="tour_h1">{bannerTitle}</h1>
          </div>
        </div>
        <Nav />
      </div> */}
      {/* <div className="private_tour">
        <img alt="private_tour" src="assets/image/0002.jpg" width="100%" />
      </div> */}
      <div className="container travel_highlight">
        <div
          class="page-head d-flex justify-content-between p-4"
          style={{ marginTop: "1.3rem" }}
        >
          <ul class="breadcrums">
            <Link to="/" class="b-link">
              Home
            </Link>
            <span className="s_span ">&raquo;</span>
            <Link to="#" class="b-link active">
              About Us-Gallery
            </Link>
          </ul>
        </div>
        <Row className="mx-0">
          {/* <Col md={2}>{null}</Col> */}
          <Col md={12}>
            <div>
              {allGallery != null ? (
                allGallery &&
                allGallery.map((pdata, index) => (
                  <div className="row mx-0">
                    {pdata != null ? (
                      pdata &&
                      pdata.map((p, index) => (
                        <div
                          className="col-md-4"
                          style={{ borderRadius: "20px" }}
                        >
                          <Row>
                            <Col
                              md={12}
                              className="p-0 colImage2 gallery_container"
                            >
                              <div className="text-center">
                                {p.primary_image ? (
                                  <>
                                    <img
                                      src={attachmenturl + p.primary_image}
                                      className="img-fluid"
                                      style={{ height: "250px", width: "100%" }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src="No_image_available.png"
                                      width="200"
                                      height="200"
                                    />
                                  </>
                                )}
                                <h2>{p.title}</h2>
                                <GalleryViewModal
                                  id={p.id}
                                  setbackdropopen={setbackdropopen}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ))
                    ) : (
                      <h1>Sorry, No Gallery Available!</h1>
                    )}
                  </div>
                ))
              ) : (
                <h1>Sorry, No Gallery Available!</h1>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="enjoy_the_spirit"></div>
      {/* <FooterNew /> */}
    </React.Fragment>
  );
}
