import React from "react";
import { ReactComponent as Phone } from "../../svg/phone.svg";
import { ReactComponent as MapPin } from "../../svg/map-pin.svg";
import { ReactComponent as Mail } from "../../svg/mail.svg";
import { ReactComponent as Fax } from "../../svg/fax.svg";
import { ReactComponent as WhatsApp } from "../../svg/whatsapp.svg";
import Nav from "../NavBar/Nav";
import FooterNew from "../NavBar/FooterNew";
import { Link } from "react-router-dom";

export default function ContactPage() {
  return (
    <React.Fragment>
      <Nav />
      <div className="container">
        <div
          class="page-head d-flex justify-content-between p-4"
          style={{ marginTop: "1.3rem" }}
        >
          <ul class="breadcrums">
            <Link to="/" class="b-link">
              Home
            </Link>
            <span className="s_span ">&raquo;</span>
            <Link to="#" class="b-link active">
              Contact Us
            </Link>
          </ul>
        </div>
        <div>
          <div className="hero-main top_space" id="logo">
            <div className="hero-main-img">
              {/* <img src="assets/image/inside-header'.jpg" alt="Header_Image" /> */}
              <h1 className="tour_h1_new">Contact</h1>
              <p className="tour_head_text">
                Going to sacred sites in Europe? We’d love nothing more than to
                host, guide and otherwise help you!
              </p>
            </div>
          </div>
        </div>
        <div className="contact_detail p-0">
          <div className="container" style={{ marginRight: "0% auto" }}>
            <div className="row contact_us justify-content-center">
              <div className="col-md-1"></div>
              <div className="col-md-2 svg-card-3">
                <Phone width="50" height="50" strokeWidth="1" />
                <h6>Phone Number</h6>
                <p>845 388 11044</p>
              </div>
              <div className="col-md-2 svg-card-3">
                <Mail width="50" height="50" strokeWidth="1" />
                <h6>Email Address</h6>
                <p>VIP@linsoatours.com</p>
              </div>
              <div className="col-md-2 svg-card-3">
                <Fax width="50" height="50" strokeWidth="1" />
                <h6>Fax</h6>
                <p>845 352 4735</p>
              </div>
              <div className="col-md-3 svg-card-3">
                <MapPin width="50" height="50" strokeWidth="1" />
                <h6>Mailing Address</h6>
                <p className="nomargin">11 Main Street,</p>
                <p>Monsey, NY 10952</p>
              </div>
              <div className="col-md-2 svg-card-3">
                <WhatsApp width="50" height="50" strokeWidth="1" />
                <h6>WhatsApp</h6>
                <p>
                  <a
                    href="https://wa.me/184538811044" // Replace with your WhatsApp number
                    target="_blank"
                    rel="noopener noreferrer"
                    // style={{ color: "inherit", textDecoration: "none" }}
                  >
                    Chat with us
                  </a>
                </p>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
          <div className="container">
            <div style={{ paddingLeft: "10%" }}>
              <h5 style={{ color: "black" }}>Hours:</h5>
              <p>Monday-Thursday: 10:00 a.m. to 6:00 p.m.</p>
              <p>Sunday: 11:00 a.m. to 4:00 p.m.</p>
              <p style={{ fontStyle: "italic" }}>
                Closed on Saturdays and Jewish holidays.
              </p>
            </div>
            <br />
            <p
              className="tour_head_text"
              style={{ textAlign: "center", fontStyle: "italic" }}
            >
              <b>
                For immediate assistance with urgent matters,
                <br />
                call our live 24/7 hotline at:
                <br />
                845 388 11044 .
              </b>
            </p>
          </div>
        </div>
      </div>
      {/* <FooterNew /> */}
      {/* <div className="subComponent-lg" id="contactBody">
        <Container>
          <header className="headerTitle text-center">
            <h1>Contact</h1>
            <p>Going to sacred sites in Europe?  We’d love nothing more than to host, guide and otherwise help you!</p>
          </header>
          <section className="svg-group text-center">
            <Row>
              <Col lg="4" md="4">
                <div className="svg-card-3">
                  <Phone width="50" height="55" strokeWidth="1" />
                  <p>845 388 11044</p>
                </div>
              </Col>
              <Col lg="4" md="4">
                <div className="svg-card-3">
                  <MapPin width="55" height="55" strokeWidth="1" />
                  <p>11 Main Street,</p>
                  <p>Monsey,NY 10952</p>
                </div>
              </Col>
              <Col lg="4" md="4">
                <div className="svg-card-3">
                  <Mail width="55" height="55" strokeWidth="1" />
                  <p>VIP@linsoatoura.com</p>
                </div>
              </Col>
            </Row>
          </section>
          </Container>
          </div> */}
    </React.Fragment>
  );
}
