import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { Col, Row } from "reactstrap";
import Nav from "./../NavBar/Nav";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FooterNew from "../NavBar/FooterNew";

export default function Faq() {
  const [backdropopen, setbackdropopen] = useState(false);
  const [allFaq, setFaqData] = useState();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    getAllFaq();
  }, []);

  const getAllFaq = () => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api1}/gallery/all_faq?status=1&sortby=order&Order=ASC`)
      .then(
        (response) => {
          setFaqData(response.data.FAQ);
          setbackdropopen(false);
        },
        (error) => {
          setFaqData();
          setbackdropopen(false);
          //console.log(JSON.stringify(error));
        }
      );
  };

  return (
    <React.Fragment>
      <div style={{ position: "relative" }}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropopen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Nav />
        <div className="hero-main" id="logo">
          <div className="hero-main-img">
            <img src="assets/image/inside-header'.jpg" alt="Header_Image" />
            <h1 className="tour_h1">FAQs</h1>
          </div>
        </div>

        <div className="contact_detail p-0">
          <div className="contact_detail_3 p-0">
            <div className="about">
              <div className="maincon">
                <div id="booking" className="section">
                  <div className="section-center">
                    <div className="container">
                      <Row>
                        <Col md={12}>
                          <div>
                            <Row>
                              <Col md={12}>
                                {allFaq != null ? (
                                  allFaq &&
                                  allFaq.map((p, index) => (
                                    <Accordion
                                      style={{
                                        marginBottom: "25px",
                                        borderRadius: "10px",
                                        // width: "70%",
                                        marginLeft: "2%",
                                      }}
                                      expanded={
                                        expanded === `panel${index + 1}`
                                      }
                                      onChange={handleChange(
                                        `panel${index + 1}`
                                      )}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        <Typography>
                                          <p className="poppins_font faq_title">
                                            {p.title}
                                          </p>
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Typography>{p.description}</Typography>
                                      </AccordionDetails>
                                    </Accordion>
                                  ))
                                ) : (
                                  <p>{null}</p>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <FooterNew /> */}
      </div>
    </React.Fragment>
  );
}
