import React from "react";
// import "./info.css";
import Nav from "../NavBar/Nav";
import FooterNew from "../NavBar/FooterNew";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  CssBaseline,
} from "@mui/material";
import foglar from "../../assets/foglar.webp";
import { Link } from "react-router-dom";

function InfoPage() {
  return (
    <>
      <Nav />
      <Container component="main" maxWidth="md">
        <div
          class="page-head d-flex justify-content-between p-4"
          style={{ marginTop: "1.3rem" }}
        >
          <ul class="breadcrums">
            <Link to="/" class="b-link">
              Home
            </Link>
            <span className="s_span ">&raquo;</span>
            {/* <Link to="#" class="b-link">
              Linsoa – Fly N Claim
            </Link>
            <span className="s_span ">&raquo;</span> */}
            <Link to="#" class="b-link active">
              Info And Disclaimer
            </Link>
          </ul>
        </div>
        <CssBaseline />
        <Box
          sx={{
            position: "relative",
            marginTop: 4,
            marginBottom: 4,
            padding: 2,
            backgroundColor: "#fff",
            borderRadius: 2,
            boxShadow: 1,
          }}
        >
          {/* <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={foglar}
              alt="Logo"
              style={{ width: "150px", height: "auto", marginRight: "20px" }}
            />
          </div> */}

          <Typography variant="h4" component="h1" align="center" gutterBottom>
            Terms and Conditions
          </Typography>
          <Typography variant="h5" component="h1" align="center" gutterBottom>
            Flight Compensation Services
          </Typography>

          <Box component="section" mb={3}>
            <Typography variant="h5" component="h2" gutterBottom>
              §1 Scope of services
            </Typography>
            <Typography paragraph>
              1.The Provider provides services in the scope of submission of
              requests on behalf of passengers to airlines for the settlement of
              their claims and the enforcement of their rights, in particular in
              relation to Regulation (EC) No 261/2004 of the European Parliament
              and of the Council of 11 February 2004 establishing common rules
              on compensation and assistance to passengers in the event of
              denied boarding and of cancellation or long delay of flights, and
              repealing Regulation (EEC) No 295/91.
            </Typography>
            <Typography paragraph>
              2.The services are provided on the basis of an assignment of
              rights, i.e. air passengers assign their claims against the
              particular airlines to the Provider, authorizing the Provider to
              assert claims in its own name, in exchange for which the Provider
              charges a fee as per the further parts of these Terms and
              Conditions.
            </Typography>
          </Box>

          <Divider />

          <Box component="section" mb={3}>
            <Typography variant="h5" component="h2" gutterBottom>
              §2 Definitions
            </Typography>
            <Typography variant="h6" component="h6" gutterBottom>
              1.For the purpose of the cooperation between the Parties the
              following definitions shall apply:
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary="Provider"
                  secondary="Kevin Foglar, conducting business under the name of Foglar Law Firm Kevin Foglar, registered at ul. Węgierska 33p, 33-340 Stary Sącz, Poland, NIP: 7343586718."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Client"
                  secondary="An airline passenger who has provided the Provider with a signed Assignment Agreement."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Assignment Agreement"
                  secondary="A document signed between the Client and the Provider, on the basis of which the Client assigns to the Provider its claims against a particular airline authorizing the Provider to assert claims in its own name. These Terms and Conditions constitute an integral part of the Assignment Agreement."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Contract"
                  secondary="The entirety of the agreement between the Provider and the Client, consisting of both the Assignment Agreement and these Terms and Conditions."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Regulation"
                  secondary="Regulation (EC) No 261/2004 of the European Parliament and of the Council of 11 February 2004 establishing common rules on compensation and assistance to passengers in the event of denied boarding and of cancellation or long delay of flights, and repealing Regulation (EEC) No 295/91."
                />
              </ListItem>
            </List>
          </Box>

          <Divider />

          <Box component="section" mb={3}>
            <Typography variant="h5" component="h2" gutterBottom>
              §3 Engagement procedure
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="1. Potential clients who believe they have a claim against an airline for a delayed or canceled flight may contact the Provider and share the details of their claim in order to assess its eligibility." />
              </ListItem>
              <ListItem>
                <ListItemText primary="2. Upon contacting the Provider, the potential client may be asked for additional documents or information in order to assess the eligibility of the potential client’s claim." />
              </ListItem>
              <ListItem>
                <ListItemText primary="3. Upon analysis of the provided information and documents the Provider shall inform the potential client about the eligibility (or non-eligibility) of their claim. If the Provider believes the potential client may be eligible for compensation, it shall provide the potential client with a draft Assignment Agreement." />
              </ListItem>
              <ListItem>
                <ListItemText primary="4. Upon the potential client’s signing and sending of a scanned copy of the Assignment Agreement to the Provider, the Contract between the Parties is concluded and the potential client is thereafter considered a Client." />
              </ListItem>
            </List>
          </Box>

          <Divider />

          <Box component="section" mb={3}>
            <Typography variant="h5" component="h2" gutterBottom>
              §4 Obligations of the Parties
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="1. In order to allow the Provider to properly provide its services the Client is obliged to share to the Provider all necessary information and documents." />
              </ListItem>
              <ListItem>
                <ListItemText primary="2. By assigning the Client’s claims to the Provider the Client guarantees that he is fully entitled to the claims which he is assigning and that he has not engaged any third parties in the scope of asserting the assigned claims (and if such a third party was engaged, the Client is obliged to terminate that engagement)." />
              </ListItem>
              <ListItem>
                <ListItemText primary="3. As part of the Provider’s services it shall assert the Client’s claims before airlines and National Enforcement Bodies as defined by the Regulation. For the avoidance of doubt, the services do not include filing lawsuits in courts - such services are subject to additional fees and may be provided on the basis of a separate agreement between the Parties." />
              </ListItem>
              <ListItem>
                <ListItemText primary="4. As part of the Assignment Agreement the Provider shall be entitled to collect compensation on behalf of the Client. The Provider is obliged to transfer any such received compensation to the Client, after offsetting the agreed fees for the services provided." />
              </ListItem>
              <ListItem>
                <ListItemText primary="5. The Provider is entitled to make decisions as to the settlement of claims at its own discretion. The Provider may, in particular, accept offers made by airlines and negotiate settlements at its own discretion." />
              </ListItem>
              <ListItem>
                <ListItemText primary="6. The Provider may authorize third parties, in particular its employees and contractors, to assist it in the provision of services." />
              </ListItem>
            </List>
          </Box>

          <Divider />

          <Box component="section" mb={3}>
            <Typography variant="h5" component="h2" gutterBottom>
              §5 Fees
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="1. Upon the successful receipt of any compensation the Provider shall charge a fee equal to 30% of the successfully obtained amounts. The remaining compensation shall be transferred to the bank account specified by the Client." />
              </ListItem>
              <ListItem>
                <ListItemText primary="2. The final amount of the compensation received by the Client from the Provider may further be decreased by potential bank fees, currency exchange rates etc. Such amounts are charged independently from the Provider’s fee specified above." />
              </ListItem>
            </List>
          </Box>

          <Divider />

          <Box component="section" mb={3}>
            <Typography variant="h5" component="h2" gutterBottom>
              §6 Limitation of liability
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="1. The Provider acts with due diligence and strives to acquire compensation on behalf of the Client. The Provider does not, however, guarantee that his services will be successful in acquiring the compensation and may therefore not be held liable solely based on the fact that the services did not result in receipt of compensation." />
              </ListItem>
              <ListItem>
                <ListItemText primary="2. The Provider shall not be held liable if it receives erroneous, incorrect or incomplete information from the Client." />
              </ListItem>
              <ListItem>
                <ListItemText primary="3. The Provider may only be held liable up to an amount equal to the fee which would have been charged by the Provider if compensation were received on behalf of the Client." />
              </ListItem>
            </List>
          </Box>

          <Divider />

          <Box component="section" mb={3}>
            <Typography variant="h5" component="h2" gutterBottom>
              §7 Personal Data
            </Typography>
            <Typography paragraph>
              1.Any personal data collected during the use of the Provider’s
              services is covered by the Privacy Policy available on the
              Provider’s website.
            </Typography>
          </Box>

          <Divider />

          <Box component="section" mb={3}>
            <Typography variant="h5" component="h2" gutterBottom>
              §8 Final provisions
            </Typography>
            <Typography paragraph>
              1.The Contract between the Client and the Provider, and any
              disputes or claims arising out of or in connection with it, shall
              be governed by the laws of Poland. Any disputes or claims arising
              out of or in connection with the Contract or its subject matter or
              formation shall be subject to the exclusive jurisdiction of the
              courts of Poland.
            </Typography>
          </Box>

          {/* <Divider /> */}

          {/* <Box
            component="footer"
            mt={3}
            textAlign="center"
            sx={{ padding: 2, backgroundColor: "#f5f5f5", borderRadius: 2 }}
          >
            <div className="d-flex justify-content-evenly fw-bold">
              <div className="d-flex flex-column justify-content-start ">
                <div variant="body2" color="textSecondary">
                  Foglar Law Firm
                </div>
                <div variant="body2" color="textSecondary">
                  NIP: 7343586718
                </div>
              </div>
              <div>claims@foglar.pl</div>
              <div>www.foglar.pl</div>
            </div>
          </Box> */}
        </Box>
      </Container>
      {/* <FooterNew /> */}
    </>
  );
}

export default InfoPage;
