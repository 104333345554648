import React, { useState, useRef } from "react";
import Nav from "../NavBar/Nav";
import FooterNew from "../NavBar/FooterNew";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import base_url from "../../api/bootapi";
import axios from "axios";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import PrintNote from "./PrintNote";
import ReactDOMServer from "react-dom/server";
import UploadModal from "../Modals/UploadModal";
import MessageModal from "../Modals/MessageModal";
import { Link } from "react-router-dom";

function ClaimStatus() {
  const [userId, setUserId] = useState("");
  const [claimStatus, setClaimStatus] = useState(null);
  const [backdropopen, setbackdropopen] = useState(false);
  const [showId, setShowId] = useState();
  const [notes, setNote] = useState();
  const iframeRef = useRef(null);
  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
  const [previousNote, setPreviousNote] = useState("");
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [customerId, setCustomerId] = useState();
  const [customerPhone, setCustomerPhone] = useState();
  const [isMultiple, setIsMultiple] = useState(false);
  const [docType, setDocType] = useState("poa");

  const openMessageModal = () => {
    setIsMessageModalOpen(true);
  };

  const closeMessageModal = () => {
    setIsMessageModalOpen(false);
  };
  const closeUploadModal = () => {
    setUploadModalIsOpen(false);
  };

  const openUploadModal = (isMultiple, docType) => {
    setIsMultiple(isMultiple);
    setDocType(docType);
    setUploadModalIsOpen(true);
  };

  const handlePrint = () => {
    const iframe = iframeRef.current;
    const doc = iframe.contentDocument || iframe.contentWindow.document;

    // Clear previous content
    doc.body.innerHTML = "";

    // Create a container element for styling
    const container = doc.createElement("div");
    container.style.fontFamily = "Arial, sans-serif";
    container.style.fontSize = "16px";
    container.style.color = "#555";

    //console.log("note", notes);

    // Render StyledNote component as HTML string
    const printNote = <PrintNote note={notes} claimId={showId} />;
    const htmlString = ReactDOMServer.renderToStaticMarkup(printNote);

    // Set the HTML content to the iframe body
    doc.body.innerHTML = htmlString;

    // Focus and print
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Remove any non-numeric characters
    const numericValue = value.replace(/\D/g, "");
    setUserId(numericValue);
  };

  const getStatusDetails = () => {
    setbackdropopen(true);

    axios
      .get(
        `${base_url.api1}/tourapp/compensation_request?unique_code=${customerPhone}`
      )
      .then((response) => {
        //console.log("31------>", response?.data?.Compensation?.id);
        setbackdropopen(false);

        setShowId(response?.data?.Compensation?.contact_no);
        setCustomerId(response?.data?.Compensation?.id);
        setClaimStatus(response?.data?.Compensation?.status.toUpperCase());
        setPreviousNote(response?.data?.Compensation?.note);
        setNote(response?.data?.Compensation?.note);
        setCustomerPhone(response?.data?.Compensation?.contact_no);

        //   setStatus(response.data.status);
      })
      .catch((error) => {
        //console.log("Error");
        setbackdropopen(false);
      });
  };

  const handleCheckStatus = () => {
    if (!userId) {
      return;
    }
    if (claimStatus !== null && userId) {
      setClaimStatus(null);
    }
    setbackdropopen(true);

    axios
      .get(
        `${base_url.api1}/tourapp/compensation_request?unique_code=${userId}`
      )
      .then((response) => {
        //console.log("31------>", response?.data?.Compensation?.id);
        setbackdropopen(false);
        setUserId("");
        setShowId(response?.data?.Compensation?.contact_no);
        setCustomerId(response?.data?.Compensation?.id);
        setClaimStatus(response?.data?.Compensation?.status.toUpperCase());
        setPreviousNote(response?.data?.Compensation?.note);
        setNote(response?.data?.Compensation?.note);
        setCustomerPhone(response?.data?.Compensation?.contact_no);
        //   setStatus(response.data.status);
      })
      .catch((error) => {
        //console.log("Error");
        setbackdropopen(false);
        Swal.fire({
          icon: "error",
          title: "No Record Found",
          html: `No record found for claim ID:<strong>${userId}</strong>`,
          confirmButtonText: "Ok",
        });
        setUserId("");
      });
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Nav />

      <div style={{ margin: "35px 0" }}>
        <div className="container mt-4">
          <div
            class="page-head d-flex justify-content-between p-4"
            style={{ marginTop: "1.3rem" }}
          >
            <ul class="breadcrums">
              <Link to="/" class="b-link">
                Home
              </Link>
              <span className="s_span ">&raquo;</span>
              {/* <Link to="#" class="b-link">
                Linsoa – Fly N Claim
              </Link>
              <span className="s_span ">&raquo;</span> */}
              <Link to="#" class="b-link active">
                Claim Status
              </Link>
            </ul>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card shadow">
                <div className="card-body" style={{ minHeight: "400px" }}>
                  <h5
                    className="card-title text-center mb-4"
                    style={{
                      fontFamily: "Arial, sans-serif",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "#007bff",
                    }}
                  >
                    Check Your Claim Status
                  </h5>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Claim ID"
                      value={userId}
                      onChange={handleInputChange}
                      style={{
                        borderRadius: "20px 0 0 20px",
                        borderColor: "#007bff",
                      }}
                    />
                    <div className="input-group-append">
                      <Button
                        variant="primary"
                        type="button"
                        onClick={handleCheckStatus}
                        style={{ borderRadius: "0 20px 20px 0" }}
                      >
                        Check Status
                      </Button>
                    </div>
                  </div>

                  {claimStatus !== null && (
                    <div className="card mt-3">
                      <div className="card-body" style={{ minHeight: "400px" }}>
                        <h6
                          className="card-title"
                          style={{
                            fontFamily: "Arial, sans-serif",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Claim Status
                        </h6>
                        <p
                          className="card-text"
                          style={{
                            fontFamily: "Arial, sans-serif",
                            fontSize: "16px",
                            color: "#555",
                          }}
                        >
                          The status for your claim ID <strong>{showId}</strong>{" "}
                          is: <strong>{claimStatus}</strong>
                        </p>
                        {notes && (
                          <div
                            className="mt-3"
                            style={{ maxHeight: "400px", overflowY: "auto" }}
                          >
                            <h6
                              className="card-title"
                              style={{
                                fontFamily: "Arial, sans-serif",
                                fontSize: "18px",
                                fontWeight: "bold",
                              }}
                            >
                              Note
                            </h6>
                            {notes.map((note, index) => (
                              <div
                                key={index}
                                className="note-item"
                                style={{ marginBottom: "20px" }}
                              >
                                <p
                                  className="note-user"
                                  style={{
                                    fontFamily: "Arial, sans-serif",
                                    fontSize: "16px",
                                    color: "#555",
                                  }}
                                >
                                  <span
                                    className="note-timestamp"
                                    style={{
                                      fontFamily: "Arial, sans-serif",
                                      fontSize: "14px",
                                      color: "#888",
                                    }}
                                  >
                                    {note.timestamp}
                                  </span>
                                  <br />
                                  From{" "}
                                  {note.user === "customer"
                                    ? "Customer"
                                    : note.user.charAt(0).toUpperCase() +
                                      note.user.slice(1)}
                                  :{" "}
                                  <span
                                    className="note-text"
                                    style={{
                                      fontFamily: "Arial, sans-serif",
                                      fontSize: "16px",
                                      color: "#333",
                                    }}
                                  >
                                    {note.note}
                                  </span>
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {claimStatus !== null && (
              <>
                <div className="col-md-2 mt-3">
                  <div style={{ marginBottom: "20px" }}>
                    {" "}
                    <button className="btn btn-success" onClick={handlePrint}>
                      Print
                    </button>
                    <iframe ref={iframeRef} style={{ display: "none" }} />
                  </div>

                  <div style={{ marginBottom: "20px" }}>
                    {" "}
                    <button
                      className="btn btn-primary"
                      onClick={() => openUploadModal(true, "all")}
                    >
                      Upload Doc.
                    </button>
                    <UploadModal
                      isOpen={uploadModalIsOpen}
                      onRequestClose={closeUploadModal}
                      claimId={customerId}
                      isMultiple={isMultiple}
                      docType={docType}
                    />
                  </div>
                  <div>
                    {" "}
                    <button
                      className="btn btn-danger"
                      onClick={openMessageModal}
                    >
                      Message
                    </button>
                    <MessageModal
                      isOpen={isMessageModalOpen}
                      onRequestClose={closeMessageModal}
                      claimId={customerId}
                      previousNote={previousNote}
                      getStatusDetails={getStatusDetails}
                    />
                  </div>
                  <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                    {" "}
                    <button
                      className="btn btn-primary"
                      onClick={() => openUploadModal(false, "poa")}
                    >
                      Upload POA
                    </button>
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    {" "}
                    <button
                      className="btn btn-primary"
                      onClick={() => openUploadModal(false, "agreement")}
                    >
                      Upload Agreement
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* <FooterNew /> */}
    </div>
  );
}

export default ClaimStatus;
