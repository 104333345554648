import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";

import Modal from "react-modal";
import ClaimForm from "./components/Claim/ClaimForm";
import Home from "./components/Home/Home";
import ContactPage from "./components/Contact/ContactPage";
import ClaimStatus from "./components/Claim/ClaimStatus";
import InfoPage from "./components/Claim/InfoPage";
import FaqPage from "./components/FAQ/FaqPage";
import Gallery from "./components/Gallery/Gallery";
import ThankYou from "./components/Claim/ThankYou";

Modal.setAppElement("#root");
function App() {
  return (
    <Routes>
      <Route path="/" element={<ClaimForm />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/claim" element={<ClaimForm />} />
      <Route path="/claimstatus" element={<ClaimStatus />} />
      <Route path="/info" element={<InfoPage />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/thank-you-page" element={<ThankYou />} />
    </Routes>
  );
}

export default App;
